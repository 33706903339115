<template>
    <div id="mini-basket-holder" v-if="basket.show_mini_basket">
        <div class="mini-basket">
            <button class="close" @click="basket.toggleMiniBasket"><img src="https://www.alternativeflooring.com/assets/images/icons/m-close.svg" alt="close basket"></button>           
            <h3>Shopping Basket ({{ basket.contents.items.length }})  <a href="/basket" style="font-size:0.8rem; float:right; padding-right: 1rem; color: #A3A3A3;">View<span class="show-for-medium"> Basket</span></a></h3>
            <template v-if="!basket.loaded">
                <p class="text-center" style="margin:1rem auto;">Loading ...</p>
            </template>
            <template v-else>
                <template v-if="basket.isEmpty">
                    <p class="text-center" style="margin:1rem auto;">Your basket is empty</p>
                </template>
                <template v-else>
                    <div>
                        <basket-item v-for="(item,index) in basket.contents.items" :index="index" :readonly="isReadonly"></basket-item>
                    </div>
                    <div class="grid-x footer">
                        <div class="cell" v-for="(item, index) in basket.contents.additionalitems">
                            <div class="grid-x">
                                <div class="small-6 medium-4 medium-offset-4 cell"><p>{{ item.description }}</p></div>
                                <div class="small-6 medium-4 cell text-right"><p v-html="item.cost"></p></div>
                            </div>
                        </div>
                        <div class="small-6 medium-4 medium-offset-4 cell"><p class="total">Total</p></div>
                        <div class="small-6 medium-4 cell text-right"><p class="total" v-html="basket.contents.totalvalue"></p></div>
                        <div class="medium-8 medium-offset-4 cell"><a href="/basket" class="button expanded view">View Shopping Basket</a></div>
                        <div class="medium-8 medium-offset-4 cell"><a href="/basket/checkout" class="button expanded">Proceed to Checkout</a></div>
                    </div>
                </template>
                <div v-if="!basket.isValid" class="alert">
                    There are products in your basket that are not avaiable in your region. Please remove these items to continue.
                </div>

            </template>
        </div>
    </div>
</template>
<script setup>
    import { useBasketStore } from '@/stores/basket'
    import BasketItem from './basket-item.vue'

    const basket = useBasketStore()

    defineOptions({
        name: 'Mini Basket'
    })
</script>
<style lang="scss">
    .mini-basket-wrapper {
        position: relative;
    }
    .mini-basket {
        background: white;
        color: var(--site-colour);
        z-index: 9999;
    }
</style>