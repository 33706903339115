var RendiliCore = {
	Settings: {
		OnUnauthorized: null,
		OnForbidden: null
	},
	Json: {
		Get: function(parameters) {
			return RendiliCore._base('GET', parameters, 'json')

		},
		Post: function(parameters) {
			return RendiliCore._base('POST', parameters, 'json')
		},
		PostJsonData: function(parameters) {
			if (typeof parameters == 'string') {
				parameters = { url: parameters }
			}
			parameters.data = JSON.stringify(parameters.data)
			return RendiliCore._base('POST', parameters, 'json')
		}
	},
	Raw: {
		Get: function(parameters) {
			return RendiliCore._base('GET', parameters, 'text');
		},
		Post: function(parameters) {
			return RendiliCore._base('POST', parameters, 'text')
		},
		PostJsonData: function(parameters) {
			if (typeof parameters == 'string') {
				parameters = { url: parameters }
			}
			parameters.data = JSON.stringify(parameters.data)
			return RendiliCore._base('POST', parameters, 'text')
		}
	},
	_base: function(method, parameters = null, dataType = 'text') {
		if (typeof parameters == 'string') {
			parameters = { url: parameters }
		}
		var responsePromise = {
			onSuccess: null,
			onError: null,
			onUnauthorized: null,
			onForbidden: null,
			then: function(success, error, onUnauthorized, onForbidden) {
				this.onSuccess = success
				this.onError = error
				this.onUnauthorized = onUnauthorized == null ? RendiliCore.Settings.OnUnauthorized : onUnauthorized
				this.onForbidden = onForbidden == null ? RendiliCore.Settings.OnForbidden : onForbidden
			}
		};
		var headers = {
			headers: {
				'X-REQUESTED-WITH': 'XMLHttpRequest'
			},
			method: method,
		}
		if (method == 'POST') {
			if (typeof parameters.data == 'string') {
				headers.body = parameters.data
			} else {
				headers.body = new URLSearchParams(parameters.data).toString()
				headers.headers['Content-Type'] = 'application/x-www-form-urlencoded'
			}
		}
		fetch(parameters.url + (parameters != null && method == 'GET' ? '?' + new URLSearchParams(parameters.data).toString() : ''), headers).then(response => {
			if (response.ok) {
				if (dataType == 'text') {
					if (responsePromise.onSuccess != null) {
						responsePromise.onSuccess(response)
					}				
				} else {
					response.json().then(response => {
						if (responsePromise.onSuccess != null) {
							responsePromise.onSuccess(response)
						}
					})
				}
			} else {
				switch(response.status) {
					case 401:
						if (responsePromise.onUnauthorized != null) {
							responsePromise.onUnauthorized(response, response.status)
						}
						break
					case 403:
						if (responsePromise.onForbidden != null) {
							responsePromise.onForbidden(response, response.status)
						}
						break
					default:
						if (responsePromise.onError != null) {
							responsePromise.onError(response, response.status)
						}			
				}
			}
		})
		return responsePromise;
	}
}

export default RendiliCore;