<template>
    <ul class="vertical menu">
        <li v-for="(child, index) in Object.keys(model)">
            <a v-if="props.level == 0" href="#" @mouseover="setactivelink(child)" @mouseout="removeactivelink(child)" 
            v-bind:class="[{current : activelink == child}, {parent: true}]"
            >
                {{ child }}
            </a>
            <a v-else :href="model[child]" @mouseover="setactivelink(child)" @mouseout="removeactivelink(child)" 
            v-bind:class="[{current : activelink == child}, {parent: false}]"
            >
                {{ child }}
            </a>
        </li>
    </ul>
</template>
<script setup>
    import { ref } from 'vue'
    const model = defineModel()
    const props = defineProps(['level'])
    const emit = defineEmits('setactivelink')
    const activelink = ref(null)

    function setactivelink(event) {
        activelink.value = event;
        emit('setactivelink', event);
    }

    function removeactivelink(event) {
        activelink.value = null;
        emit('setactivelink', event);
    }
</script>