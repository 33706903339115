<template>
    <div>
        <div class="input-group">
        <input type="email"  class="input-group-field" v-model="email" placeholder="Email address" aria-label="Email Address">
        <div class="input-group-button">
            <button @click="checkForm()" class="button">I'm in</button>
        </div>
        </div>
        <div class="cell" v-if="error">
            <p class="error">{{ error }}</p>
        </div>       
        <div class="cell" v-if="result">
            <p>{{ result }}</p>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import RendiliCore from '@RendiliCore'

    const email = ref('')
    const error = ref('')
    const result = ref('')

    function checkForm(e) {
        error.value = ''

        if (!email.value) {
            error.value = 'Email address is required.'
        } else if (!validEmail(this.email.value)) {
            error.value = 'Valid email is required.'
        }

        if (!error.value) {
            submitForm()
        }        
    }

    function validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }

    function submitForm() {
        RendiliCore.Json.PostJsonData({
            url: "/alternative/site/addEmailToSubscribers",
            data: {
                email: email.value
            }
        })
        .then(function (data) {
            if(data.status == "Error") {
                error.value =  'Email could not be added at time.'
            }else {
                error.value = ''
                result.value = "Congratulations, you're on the list!"
            }
            
        })
    }

    defineOptions({
        name: 'Newsletter Signup'
    })
</script>