<template>
    <div v-if="basket.contents.items[props.index].qty > 0" class="grid-x item">
        <div class="small-4 medium-3 cell"><img :src="basket.contents.items[props.index].thumb" :alt="basket.contents.items[props.index].name" /></div>
        <div class="small-7 small-offset-1 hide-for-medium cell"><p v-html="basket.contents.items[props.index].name"></p></div>
        <div class="small-12 medium-9 cell">
            <div class="grid-x detail">
                <div class="cell hide-for-small-only"><p v-html="basket.contents.items[props.index].name" :class="{ 'disabled': !basket.contents.items[props.index].enabled }"></p></div>
                <div v-if="basket.contents.items[props.index].options.length " class="cell">
                    <div v-if="props.readonly || !basket.contents.items[props.index].enabled" :class="{ 'disabled': !basket.contents.items[props.index].enabled }">
                        {{ chosenOptionName }}
                    </div>
                    <div v-else>
                        <select v-model="basket.contents.items[props.index].size" @change="update">
                            <option v-for="(option,key) in basket.contents.items[props.index].options" v-bind:value="option.code">{{ option.name }}<span v-if="option.price > 0" v-html="'@ ' + option.price"></span></option>
                        </select>
                    </div>
                </div>
                <div v-else-if="basket.contents.items[props.index].size" class="cell">
                    <p :class="{ 'disabled': !basket.contents.items[props.index].enabled }"><b>Size:</b> {{ basket.contents.items[props.index].size }}</p>
                </div>
                <div class="cell" v-if="basket.contents.items[props.index].extras.length"><b>Extras:</b></div>
                <div class="cell" v-for="extra in basket.contents.items[props.index].extras"><p class="extra">{{ extra.name }} <span class="show-for-medium" v-html="'(' + extra.cost + (extra.vat == '' ? '' : ' ' + extra.vat) + ')'"></span></p></div>
                <div v-if="!props.readonly" class="cell" v-for="(extra, index) in basket.contents.items[props.index].selectable_extras"><p class="selectable-extra"><input :id="'extra_item_' + _uid + '_' + index" type="checkbox" v-model="extra.chosen" v-on:change="$emit('itemChanged', extra.code)" /><label :for="'extra_item_' + _uid + '_' + index">{{ extra.name }}</label></p></div>
            </div>
            <div class="grid-x detail">
                <div class="cell" v-if="props.screensize == 'small'">
                    <div class="grid-x">
                        <div class="small-2 cell"><label class="middle">Qty: </label></div>
                        <div class="small-3 cell"><p class="price">{{basket.contents.items[props.index].qty}}</p></div>
                        <div class="small-3 cell"><p class="price"><span v-html="basket.contents.items[props.index].lineprice"></span></p></div>    
                    </div>
                </div>                 
                <div class="cell" v-else>
                    <div class="grid-x">
                        <div class="small-1 cell"><label class="middle">Qty: </label></div>
                        <div class="small-5 cell">
                            <label v-if="props.readonly || basket.contents.items[props.index].type == 'remnant' || !basket.contents.items[props.index].enabled" class="middle">
                                {{ basket.contents.items[props.index].qty }}
                            </label>
                            <div v-else class="input-group">
                                <div class="input-group-button">
                                    <button class="tiny minus button" @click="reducequantity($event)">-</button>
                                </div>
                                <input class="input-group-field" type="number" :bind="value" v-model="basket.contents.items[props.index].qty" disabled>
                                <div class="input-group-button">
                                    <button class="tiny plus button" @click="increasequantity($event)">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="small-2 cell"><label class="middle">Price:</label></div>
                        <div class="small-2 cell"><p class="price"><span v-if="basket.contents.items[props.index].enabled" v-html="basket.contents.currency_symbol + basket.contents.items[props.index].lineprice"></span><span v-else>N/A</span></p></div>
                        <div v-if="!props.readonly" class="small-2 cell"><button class="remove" v-on:click="removeitem($event)"><img src="/assets/images/icons/Remove_Bin_icn.svg" alt="remove item"  /></button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref, inject, computed } from 'vue'
    import { useBasketStore } from '@/stores/basket'

    const props = defineProps([ 'index', 'readonly' ])
    const basket = useBasketStore()
    const screensize = inject('breakpoint')

    const chosenOptionName = computed(() => {
        var item = Object.values(Object.fromEntries(Object.entries(basket.contents.items[props.index].options).filter(([key, value]) => basket.contents.items[props.index].code == basket.contents.items[props.index].size)))[0];
        if (typeof item == 'undefined') {
            return '';
        } else {
            return item.name;
        }
    })

    function update() {
        basket.updateItem(props.index)
    }

    function removeitem() {
        var name = basket.contents.items[props.index].name;
        if (name.indexOf('<') > -1) {
            name = name.substr(0, name.indexOf('<'))
        }
            

        if (confirm('Remove ' + name + ' from your basket?')) {
            basket.removeItem(props.index)
        }
    }

    function reducequantity() {
        console.log(props.index)
        basket.reduceQuantity(props.index, 1)
    }

    function increasequantity() {
        console.log(props.index)
        basket.increaseQuantity(props.index, 1)
    }
</script>