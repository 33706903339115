<template>
    <div class="search-results">
        <p v-if="props.items.length == 0" class="text-centre">
            no results found
        </p>
        <template v-else>
            <p v-for="item in pagedItems"><a :href="item.uri" v-html="item.name"></a></p>
            <vue-awesome-paginate v-if="pagination" :total-items="props.items.length" v-model="currentPage" :items-per-page="perPage" :max-pages-shown="maxPages"
            paginate-buttons-class="button hollow secondary"
                    active-page-class="button hollow secondary active"
                    back-button-class="button hollow secondary"
                    next-button-class="button hollow secondary">
            </vue-awesome-paginate>
        </template>
    </div>
</template>
<script setup>
    import { onMounted, ref, computed, watch } from 'vue'

    const props = defineProps({
        items: {
            type: Object
        }  
    })
    const currentPage = ref(1)

    const perPage = ref(10)
    const maxPages = ref(4)

    const pagination = computed(() => props.items && props.items.length > perPage.value)

    const pagedItems = computed(() => {
        if (!props.items || props.items.length == 0) {
            return []
        }
        var items = [...props.items]
        const startIndex = (currentPage.value - 1) * perPage.value
        const endIndex = startIndex + perPage.value

        return items.slice(startIndex, endIndex)
    })

    watch(() => props.items, (oldList, newList) => {
        if (oldList.length != newList.length) {
            currentPage.value = 1
        }
    })
</script>
<style lang="scss">
    .search-results {
        .pagination-container {
            margin: 1rem 0 0 0;

            button.button {
                padding: 0.3rem;
                margin: 0 0.2rem;
                border: 1px solid #444;
                color: var(--site-colour);
            }
        }
    }
</style>