<template>
	<div>
		<div :class="active == true ? 'active' : ''">
			<div class="grid-x search-holder">
				<button v-show="active == true" class="button close" v-on:click="active=false"></button>
				<input class="searchinput" name="search" type="text" placeholder="Search for products and inspiration" v-model="query" @focus="activate" aria-label="Search Alternative Flooring" />
			</div>
			<div v-if="active">
				<p class="help"></p>
			</div>
			<div class="results" v-if="showresults && active == true">
				<div class="grid-x" v-if="results.products.length == 0 && results.inspiration.length == 0">
					<p>No results found</p>
				</div>
				<div v-else class="grid-x medium-up-2">
					<div class="cell" v-if="results.products.length > 0">
						<h2>Products</h2>
						<search-results :items="results.products" />
					</div>
					<div class="cell" v-if="results.inspiration.length > 0">
						<h2>Inspiration</h2>
						<search-results :items="results.inspiration" />
					</div>
				</div>
			</div>
		</div>
		<Teleport to="#mobile-search">
			<div :class="active == true ? 'active' : ''">
				<div class="search-holder">
					<button v-show="active == true" class="button close" v-on:click="active=false"></button>
					<input class="searchinput" name="search" type="text" placeholder="Search for products and inspiration" v-model="query" @focus="activate" aria-label="Search Alternative Flooring" />
				</div>
				<div v-if="active">
					<p class="help"></p>
				</div>
				<div class="results" v-if="showresults && active == true">
					<div class="grid-x" v-if="results.products.length == 0 && results.inspiration.length == 0">
						<p>No results found</p>
					</div>
					<div v-else class="grid-x medium-up-2">
						<div class="cell" v-if="results.products.length > 0">
							<h2>Products</h2>
							<search-results :items="results.products" />
						</div>
						<div class="cell" v-if="results.inspiration.length > 0">
							<h2>Inspiration</h2>
							<search-results :items="results.inspiration" />
						</div>
					</div>
				</div>
			</div>
		</Teleport>
	</div>
</template>

<script setup>
	import { ref, watch } from 'vue'
	import RendiliCore from '@RendiliCore'
	import searchResults from '@/components/site-search/results.vue'

	// Data
	const showresults = ref(false)
	const results = ref(null)
	const query = ref(null)
	const loading = ref(false)
	const active = ref(false)
	const product_page = ref(1)
	const inspiration_page = ref(1)
	const timeOut = ref(null)

	// Methods
	function loadResults(product_page, inspiration_page) {
		if (timeOut.value != null) {
			clearTimeout(timeOut.value)
		}
		if (query.value != '') {
			loading.value = true
			RendiliCore.Json.PostJsonData({
				url: "/alternative/search", 
				data: {
					query: query.value,
					product_start: (product_page - 1) * 10,
					inspiration_start: (inspiration_page - 1) * 10
				}
			}).then(function(data) {
				results.value = data.results;
				showresults.value = true;
			})
		}
	}

	function pageChange(newPage) {
		loadResults(newPage, inspiration_page)
	}

	function activate() {
		active.value = true
	}


	// Watch
	watch(query, function() {
		if (timeOut.value != null) {
			clearTimeout(timeOut.value)
		}

		timeOut.value = setTimeout(function() {
			product_page.value = 1
			inspiration_page.value = 1
			loadResults(product_page.value, inspiration_page.value)
			gtag("event", "view_search_results", {
				search_term: query.value
			})
		}, 250)
	})

    defineOptions({
        name: 'Site Search'
    })
</script>