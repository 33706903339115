<template>
	<div>
		<div class="grid-container full">
			<div class="grid-x align-center" style="position:relative;">
			<a v-if="masterfolder == 189 || masterfolder == 397" class="homelink" href="/">Main Site</a>
			<ul class="menu topmenu align-center">
				<li v-for="(menuitem, index) in menuitems">
					<a :href="menuitem.url" @mouseover="showmega($event, index)" @mouseout="cleartimeout" @click="showmega($event, index)" v-bind:class="{current : menuitem.current }">{{ menuitem.title }}</a>
				</li>
			</ul>
			</div>
		</div>
		<div class="grid-container full">
			<mega-menu-dropdown v-if="activelink != null" v-model="menuitems[activelink]" v-on:closemenu="hidemega($event)"  @mouseover="cancelhide($event)" @mouseleave="hidemegadelayed($event)"></mega-menu-dropdown>
		</div>
	</div>
</template>
<script setup>
    import { ref } from 'vue'
	import megaMenuDropdown from './mega-menu/mega-menu-dropdown.vue'
    import RendiliCore from '@RendiliCore'

    defineOptions({
        name: 'Mega Menu'
    })
    
    const menuitems = ref(megamenu_data)
    const masterfolder = ref(megamenu_masterfolder)
    const activelink = ref(null)
    const menutimer = ref(null)
    const timeout = ref(null)

    function getmenu() {
        RendiliCore.Json.PostJsonData({
            url: '/alternative/megamenu/getmenu',
            data: {
                masterfolder : masterfolder,
                currentfolder: currentfolder,
            }
        })
        .then(function (data) {
            menuitems.value = data.items;
        });
    }

    function showmega(event, index) {
        //var vm = this;
        if (timeout.value != null) {
            clearTimeout(timeout.value);
            timeout.value = null;
        }
        if(menuitems.value[index].children.length > 0) {
            event.preventDefault();
            if (menutimer.value == null) {
                menutimer.value = setTimeout(function() {
                    activelink.value = index;
                }, 250);
            }
        } else {
            activelink.value = null;
        }
    }

    function cleartimeout() {
        if (menutimer.value != null) {
            clearTimeout(menutimer.value);
            menutimer.value = null;
        }
    }

    function hidemega() {
        activelink.value = null;
        timeout.value = null;
    }
    
    function hidemegadelayed() {
        //var self = this;
        //timeout = setTimeout(function() { self.hidemega() }, 500);
    }

    function cancelhide() {
        if (timeout.value != null) {
            clearTimeout(timeout.value);
            timeout.value = null;
        }
    }
</script>