<template>
    <div class="grid-x">
        <div class="cell" v-if="activelink >= 0" style="padding-bottom: 5rem;">
        <ul  class="vertical menu drilldown" data-drilldown>
            <li v-if="masterfolder == 189 || masterfolder == 397" class="homelink"><a href="/">Main Site</a></li>
            <li class="back" v-if="mainmenu[activelink] && mainmenu[activelink].back_link"><a href="#"  @click="getmenu(mainmenu[activelink].back_link)">Back</a></li>
            <li v-if="mainmenu[activelink]"><a :href="mainmenu[activelink].url"><span style="font-weight:bold;" v-html="mainmenu[activelink].title"></span></a></li>
            <template v-for="(menuitem, index) in mainmenu[activelink].items">
                <li v-if="mainmenu[activelink].items[index].is_parent == 0"  :key="index">
                    <a :href="menuitem.url"><span v-html="menuitem.title"></span></a>
                </li>
                <li class="parent" v-else>
                    <a href="#" @click="getmenu(menuitem.id)"><span v-html="menuitem.title"></span></a>
                </li>
            </template>
        </ul>
        </div>
    </div>
</template>
<script setup>
    import { ref, onMounted } from 'vue'
    import RendiliCore from '@RendiliCore'

    const masterfolder = ref(1)
    const mainmenu = ref([])
    const activelink = ref(-1)
    function getmenu(i) {
        activelink.value = -1;
        for(var c = 0; c < mainmenu.value.length; c++) {
            if(mainmenu.value[c].id == i) {
                activelink.value = c;
            }
        }
        if(activelink.value == -1) {
            RendiliCore.Json.Get({
                url: '/alternative/mobilemenu/getmenu/' + masterfolder.value + '/' + i
            })
            .then(function (data) {
                mainmenu.value.push(data);
                activelink.value = mainmenu.value.length - 1;
            }); 
        } 
        
    }

    onMounted(() => {
        RendiliCore.Json.Get({
            url: '/alternative/mobilemenu/getmenu/' + masterfolder.value + '/' + masterfolder.value
        })
        .then(function (data) {
            mainmenu.value.push(data);
            activelink.value = 0;
        });        
    })

    defineOptions({
        name: 'Mobile Menu'
    })
</script>
<style scoped>
li.parent {
    position: relative;
}
li.back a {
    padding-left: 1.5rem !important;
    position: relative;
}
li.parent::after{
    position: absolute;
    right:0;
    top: 10px;
    content: url(/assets/images/icons/AF_menu_arrow_right.svg);
    border-style: none;
}
li.back a::before{
    position: absolute;
    left:0;
    top: 14px;
    content: url(/assets/images/icons/AF_menu_arrow_left.svg);
    border-style: none;
}
</style>
