<template>
    <div class="grid-x" style="padding: 0 6rem;">
        <div class="medium-4 xlarge-3 cell" v-for="(child, index) in model">
            <a :href="child.url" class="qlink" :target="child.target">
                <div>
                    <img :src="child.img" />
                    <img class="icon" :src="child.icon" />
                </div>
                <span>{{ child.title }}</span>
            </a>
        </div>
    </div>
</template>
<script setup>
    const model = defineModel()
</script>
