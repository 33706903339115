import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import RendiliCore from '@RendiliCore'

export const useBasketStore = defineStore('basket', () => {
    const init = ref(null)
    const loading = ref(true)
    const loaded = ref(false)
    const contents = ref({ items: [], recipient: [], billing: [] })
    const readonly = ref(false)
    const checkout = ref(false)
    const order_reference = ref('')
    const show_mini_basket = ref(false)
    const show_mini_wishlist = ref(false)

    const isEmpty = computed(() => {
        return contents.value.items.length == 0
    })

    const isValid = computed(() => {
        return contents.value.items.filter(i => i.enabled == false).length == 0
    })

    const isLoggedIn = computed(() => {
        return contents.value.user != null
    })

    const hasRecipientDetails = computed(() => 
        contents.value.recipient.email && 
        contents.value.recipient.phone &&
        contents.value.recipient.forename && 
        contents.value.recipient.surname && 
        contents.value.recipient.addr1 && 
        contents.value.recipient.town && 
        contents.value.recipient.county && 
        contents.value.recipient.postcode &&
        contents.value.billing.addr1 && 
        contents.value.billing.town && 
        contents.value.billing.county && 
        contents.value.billing.postcode
    )

    async function getItems(forceLoad = false) {
        if (forceLoad === true || loaded.value == false) {
            if (loaded.value == false) {
                loading.value = true
            }
            contents.value = await RendiliCore.Json.Get('/basket/state')
            loaded.value = true
            loading.value = false
        }
    }

    async function updateCustomerDetails() {
        if (contents.value.recipient_as_billing) {
            contents.value.billing.addr1 = contents.value.recipient.addr1;
            contents.value.billing.addr2 = contents.value.recipient.addr2;
            contents.value.billing.addr3 = contents.value.recipient.addr3;
            contents.value.billing.town = contents.value.recipient.town;
            contents.value.billing.county = contents.value.recipient.county;
            contents.value.billing.postcode = contents.value.recipient.postcode;
        }
        await RendiliCore.Json.PostJsonData({
            url: '/basket/checkout/update-customer-details', 
            data: {
                recipient: contents.value.recipient,
                billing: contents.value.billing,
                recipient_as_billing: contents.value.recipient_as_billing
            }
        }).then(function (data) {
            if (data.status == 'OK') {
                contents.value = data.basket
            }
        })
    }

    async function updateDeliveryMethod() {
        await RendiliCore.Json.PostJsonData({
            url: '/basket/checkout/delivery-method', 
            data: {
                method: contents.value.delivery_method,
            }
        }).then(function (data) {
            if (data.status == 'OK') {
                contents.value = data.basket
            }
        })
    }

    function addItem(item) {
        contents.items.push(item)
    }

    function editItem({ commit }, { item, key, value }) {
        const index = contents.items.indexOf(item)
        contents.items.splice(index, 1, {
            ...item,
            text,
            done
        })
    }

    function removeItem(index) {
        gtag_ecom('event', 'remove_from_cart', {
            currency: contents.value.currency_code,
            value: contents.value.items[index].lineprice, 
            items: [{
                item_id: contents.value.items[index].code,
                item_name: contents.value.items[index].name,
                item_category: contents.value.items[index].type,
                quantity: contents.value.items[index].qty
            }]
        });
        contents.value.items[index].qty = 0;
        updateItem(index)
    }

    function reduceQuantity(index, amount) {
        if(contents.value.items[index].qty - amount <= 0) {
            removeItem(index)
        } else {
            contents.value.items[index].qty -= amount
            updateItem(index)
            gtag_ecom('event', 'remove_from_cart', {
                currency: contents.value.currency_code,
                value: contents.value.items[index].price, 
                items: [{
                    item_id: contents.value.items[index].code,
                    item_name: contents.value.items[index].name,
                    item_category: contents.value.items[index].type,
                    quantity: 1
                }]
            })
        }
    }

    function increaseQuantity(index, amount = 1) {
        contents.value.items[index].qty += amount
        updateItem(index)
        gtag_ecom('event', 'add_to_cart', {
                currency: contents.value.currency_code,
                value: contents.value.items[index].price, 
                items: [{
                    item_id: contents.value.items[index].code,
                    item_name: contents.value.items[index].name,
                    item_category: contents.value.items[index].type,
                    quantity: 1
                }]
        })
    }

    function updateItem(index) {
        RendiliCore.Json.PostJsonData({
            url: '/alternative/basket/updateitem', 
            data: {
                type: contents.value.items[index].type,
                size: contents.value.items[index].size,
                code: contents.value.items[index].code,
                qty: contents.value.items[index].qty,
                selectable_extras: contents.value.items[index].selectable_extras
            }
            })
            .then(function (data) {
                if(data.error) {
                    //handle
                } else {
                    contents.value = data.basket
                }
            })
    }


    function itemsAdded(data) {
        if (data.status == 'OK') {
            contents.value = data.basket
            if (data.showBasket) {
                show_mini_basket.value = true
                gtag_ecom('event', 'add_to_cart', {
                    currency: contents.value.currency_code,
                    value: data.items.reduce((n, {cost}) => n + cost, 0), 
                    items: data.items.map(item => ({
                        item_id: item.id,
                        item_name: item.name,
                        item_category: item.type,
                        price: item.cost,
                        quantity: item.qty
                    }))
                })
            }
            if (data.showWishlist) {
                show_mini_wishlist.value = true
            }
        }
    }

    function toggleMiniBasket() {
        show_mini_basket.value = !show_mini_basket.value
    }
    
    function add(code, type, qty) {
        RendiliCore.Json.PostJsonData({
            url: '/basket/add/' + qty,
            data: {
                type,
                code
            }
        })
        .then(itemsAdded)
    }

	function addRug(token, outside = false) {
        RendiliCore.Json.PostJsonData({
            url: '/basket/add-rug/' + token,
            data: {
                outside: outside
            }
        }).then(itemsAdded)
	}

	function addRugSamples(token, outside = false) {
        RendiliCore.Json.PostJsonData({
            url: '/basket/add-samples/' + token,
            data: {
                outside: outside
            }
        }).then(itemsAdded)
	}    

    function addSample(code, dest='basket') {
        RendiliCore.Json.PostJsonData({
            url: '/' + dest + '/add/1',
            data: {
                type: 'swatch',
                code
            }
        })
        .then(itemsAdded)
    }

    function addSampleList(codes, dest='basket') {
        RendiliCore.Json.PostJsonData({
            url: '/' + dest + '/add/1',
            data: {
                type: 'swatchlist',
                codes
            }
        })
        .then(itemsAdded)
    }

    function addCarpet(code, width, length, dest='basket') {
        RendiliCore.Json.PostJsonData({
            url: '/' + dest + '/add/1',
            data: {
                type: 'carpet',
                code,
                width,
                length,
                intec: 0
            }
        })
        .then(itemsAdded)
    }

    function addPremix(code, block_id, size, selectable_extras, qty = 1, dest='basket') {
        RendiliCore.Json.PostJsonData({
            url: '/' + dest + '/add/' + qty,
            data: {
                type: 'premix',
                code,
                block_id,
                size,
                selectable_extras
            }
        })
        .then(itemsAdded)
    }

    function addPremixSamples(codes) {
        RendiliCore.Json.PostJsonData({
            url: '/basket/add/1',
            data: {
                type: 'premix_swatch',
                codes
            }
        })
        .then(itemsAdded)
    }

    function addExternalPremix(code, block_id, qty = 1, dest='basket') {
        RendiliCore.Json.PostJsonData({
            url: '/' + dest + '/add/' + qty,
            data: {
                type: 'external',
                code,
                block_id
            }
        })
        .then(itemsAdded)
    }

    function addExternalPremixSample(code, block_id, dest='basket') {
        RendiliCore.Json.PostJsonData({
            url: '/' + dest + '/add/1',
            data: {
                type: 'external_swatch',
                code: code + '-sample',
                block_id
            }
        })
        .then(itemsAdded)
    }

    async function addVoucher(voucher_code) {
        var data = await RendiliCore.Json.PostJsonData({
            url: '/basket/add-voucher', 
            method: 'POST',
            data: {
                code: voucher_code
            }
        })

        if (data.status == 'OK') {
            contents.value = data.basket
            return true
        } else {
            return data.message;
        }
    }

    function updateWishlistItem(item) {
        RendiliCore.Json.PostJsonData({
        url: "/alternative/wishlist/updateitem", 
        data: {
            type: item.type,
            size: item.size,
            code: item.code,
            qty: item.qty
            }
        })
        .then(itemsAdded)
    }

    init.value = (async function () {
        if (!loaded.value) {
            loading.value = true
            await getItems()
            loading.value = false
        }
    })()

    return {
        init, loading, loaded, contents, readonly, checkout, order_reference, show_mini_basket, show_mini_wishlist,
        isEmpty, isValid, isLoggedIn, hasRecipientDetails, getItems, updateCustomerDetails, updateDeliveryMethod,
        addItem, editItem, removeItem, reduceQuantity, increaseQuantity, updateItem, toggleMiniBasket, add, addRug,
        addRugSamples, addSample, addSampleList, addCarpet, addPremix, addPremixSamples, addExternalPremix,
        addExternalPremixSample, addVoucher, updateWishlistItem
    }
})