<template>
    <ul class="vertical menu">
        <li v-for="(child, index) in model">
            <a :href="child.url" @mouseover="setactivelink(index)" @mouseout="removeactivelink(index)" v-bind:class="[{current : activelink == index}, {parent: child.grandchildren && child.grandchildren.length > 0}]">
                <span v-if="child.icon" style="display: inline-block; width: 45px;"><img :src="child.icon" :alt="child.title" /></span>
                {{ child.title }}
            </a>
        </li>
    </ul>
</template>
<script setup>
    import { ref, defineEmits } from 'vue';
    const model = defineModel()
    const activelink = ref(null)
    const emit = defineEmits('setactivelink')

    function setactivelink(event) {
        activelink.value = event;
        emit('setactivelink', event);
    }

    function removeactivelink(event) {
        activelink.value = null;
        emit('setactivelink', event);
    }
</script>