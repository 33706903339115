<template>
    <div class="top-menu">
        <ul class="menu right">
            <li><a href="/account" :class="'account ' + basket.contents.user" aria-label="Account">Account</a></li>
            <li class="mini-wishlist"><a href="/wishlist" :class="'wishlist ' + basket?.contents?.wishlist?.status" aria-label="Wishlist">Wishlist</a></li>
            <li><button type="button" :class="{ 'link basket': true, on: basket?.contents?.items.length > 0 }" @click="basket.toggleMiniBasket">Basket</button></li>
        </ul>
        <div class="mini-wishlist-holder" v-if="basket.show_mini_wishlist">
            <div class="paint-button">
                <button class="ok" @click="basket.show_mini_wishlist = false"></button>
                <p>Your favourite products have been saved in your wishlist, click the heart to view them anytime.<br />
                <a href="/wishlist">View Wishlist</a></p>
            </div>
        </div>
        <Teleport to="#top-menu-mobile">
            <div class="top-menu">
                <ul class="menu right">
                    <li><a href="/account" :class="'account ' + basket.contents.user" aria-label="Account"></a></li>
                    <li class="mini-wishlist"><a href="/wishlist" :class="'wishlist ' + basket?.contents?.wishlist?.status" aria-label="Wishlist"></a></li>
                    <li><button type="button" :class="{ 'link basket': true, on: basket?.contents?.items.length > 0 }" @click="basket.toggleMiniBasket"></button></li>
                </ul>
                <div class="mini-wishlist-holder" v-if="basket.show_mini_wishlist">
                    <div class="paint-button">
                        <button class="ok" @click="basket.show_mini_wishlist = false"></button>
                        <p>Your favourite products have been saved in your wishlist, click the heart to view them anytime.<br />
                        <a href="/wishlist">View Wishlist</a></p>
                    </div>
                </div>
            </div>
        </Teleport>
        <Teleport to="#mini-basket-display">
            <mini-basket></mini-basket>
        </Teleport>
    </div>
</template>
<script setup>
    import { useBasketStore } from '@/stores/basket'
    import miniBasket from '@/pages/basket/mini-basket/mini-basket.vue'

    const props = defineProps({
        'isMobile': {
            type: Boolean,
            default: false
        }
    })
    const basket = useBasketStore()

    document.getElementById('top-menu-mobile').innerHTML = '';

    defineOptions({
        name: 'Top Menu'
    })
</script>