import { createApp } from 'vue'
import pinia from '@/helpers/pinia'
import megaMenu from '@/components/mega-menu.vue'
import mobileMenu from '@/components/mobile-menu.vue'
import siteSearch from '@/components/site-search.vue'
import topMenu from '@/components/top-menu.vue'
import newsleterSignup from '@/components/newsletter-signup.vue'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import RendiliCore from '@RendiliCore'

RendiliCore.Settings.OnUnauthorized = function () {
    $('#signinModal').foundation('open');
};

RendiliCore.Settings.OnForbidden = function () {
    window.location.replace("/");
};

createApp(megaMenu).mount('#megamenu-container')
createApp(mobileMenu).mount('#mobilemenu-container')
createApp(topMenu).use(pinia).mount('#top-menu')
createApp(siteSearch).use(VueAwesomePaginate).mount('#desktop-search')
createApp(newsleterSignup).mount('#emailformentry')
