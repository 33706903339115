<template>
	<div>
		<a :href="props.value.link">
			<img :src="props.value.image" :alt="props.value.text">
		</a>
		<br>
		<p><a :href="props.value.link">{{ props.value.text }}</a></p>
	</div>
</template>
<script setup>
    const props = defineProps(['value'])
</script>
